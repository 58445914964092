// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes glowing {
    0% {
      background-color:  rgba(255, 255, 255, 0.985); /* Light orange color with reduced opacity */
      box-shadow: 0 0 5px rgba(255, 215, 0, 0.1); /* Light orange color with reduced opacity */
    }
    50% {
      background-color: rgba(255, 255, 255, 0.985); /* Dark orange color */
      box-shadow: 0 0 20px #FFA500; /* Dark orange color */
    }
    100% {
      background-color: rgba(255, 255, 255, 0.985); /* Light orange color with reduced opacity */
      box-shadow: 0 0 5px rgba(255, 215, 0, 0.1); /* Light orange color with reduced opacity */
    }
    
  }`, "",{"version":3,"sources":["webpack://./src/components/NavBar.css"],"names":[],"mappings":"AAAA;IACI;MACE,6CAA6C,EAAE,4CAA4C;MAC3F,0CAA0C,EAAE,4CAA4C;IAC1F;IACA;MACE,4CAA4C,EAAE,sBAAsB;MACpE,4BAA4B,EAAE,sBAAsB;IACtD;IACA;MACE,4CAA4C,EAAE,4CAA4C;MAC1F,0CAA0C,EAAE,4CAA4C;IAC1F;;EAEF","sourcesContent":["@keyframes glowing {\n    0% {\n      background-color:  rgba(255, 255, 255, 0.985); /* Light orange color with reduced opacity */\n      box-shadow: 0 0 5px rgba(255, 215, 0, 0.1); /* Light orange color with reduced opacity */\n    }\n    50% {\n      background-color: rgba(255, 255, 255, 0.985); /* Dark orange color */\n      box-shadow: 0 0 20px #FFA500; /* Dark orange color */\n    }\n    100% {\n      background-color: rgba(255, 255, 255, 0.985); /* Light orange color with reduced opacity */\n      box-shadow: 0 0 5px rgba(255, 215, 0, 0.1); /* Light orange color with reduced opacity */\n    }\n    \n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
